@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');

body {
  margin: 0;
  font-family: 'Noto Sans TC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Noto Sans TC', sans-serif;
}

.changePasswordContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-top: 20px;
}

.changePasswordContent {
  display : flex;
  flex-direction: column;
  margin: 20px;
  justify-content: center;
}

.changePasswordLabelAndInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.changePasswordLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.changePasswordInput {
  display: flex;
  flex-direction: column;
}

.changePasswordButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.changePasswordButton {
  margin: 10px;
}

.passwordMention {
  color: orangered;
  font-size: 12px;
}

.resetPasswordContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
}

.resetPasswordInputField {
  width: 45%;
}

.resetPasswordButtonContainer {
  display: flex;
  margin: 10px;
  justify-content: flex-end;
}