.form-control {
    width: 50%;
}



/*.company_name_text {*/
/*    text-align: center;*/
/*    color: rgb(20, 52, 104);*/
/*}*/

/*.narrow-input {*/
/*    width: 100%;*/
/*    padding: 10px;*/

/*}*/

/*.login-form {*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*}*/

/*.container-div {*/
/*    margin-top: 80px;*/
/*}*/
